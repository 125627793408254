import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { TalkJsDomainService } from '@domain/talk-js-domain.service';
import { Observable, of } from 'rxjs';

function initializeTalkJs(talkJsDomainService: TalkJsDomainService) {
  return (): Observable<boolean> => {
    talkJsDomainService.checkTalkJsInitialization$().subscribe();
    return of(true);
  };
}

export const talkJsInitializerProvider: EnvironmentProviders = provideAppInitializer(() => {
  const initializerFn = initializeTalkJs(inject(TalkJsDomainService));
  return initializerFn();
});
