import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HistoryService } from '../services/history/history.service';

function initializeHistory(historyService: HistoryService) {
  return (): Observable<boolean> => {
    historyService.initHistory();
    return of(true);
  };
}

export const historyInitializerProvider: EnvironmentProviders = provideAppInitializer(() => {
  const initializerFn = initializeHistory(inject(HistoryService));
  return initializerFn();
});
