import { AuthService } from '@ess-front/shared';
import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { UserDomainService } from '@app/domain/user-domain.service';
import { Observable, of, take } from 'rxjs';

function initializeUser(authService: AuthService, userDomainService: UserDomainService) {
  return (): Observable<boolean> => {
    if (authService.isAuthenticated()) {
      userDomainService.getUser$().pipe(take(1)).subscribe();
    }
    return of(true);
  };
}

export const userInitializerProvider: EnvironmentProviders = provideAppInitializer(() => {
  const initializerFn = initializeUser(inject(AuthService), inject(UserDomainService));
  return initializerFn();
});
