import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { StoreService } from '@app/store/store.service';
import { LoadingSpinnerComponent } from '@shared/loading-spinner/loading-spinner.component';
import { LoadingState } from '@shared/models/store/loading-state.model';
import { Observable } from 'rxjs';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-loading-screen',
  imports: [CommonModule, LoadingSpinnerComponent, TranslocoDirective],
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent {
  public color: 'white' | 'black';
  public loadingState$: Observable<LoadingState>;
  public messageKey: string;

  constructor(
    private store: StoreService,
    private router: Router,
  ) {
    this.loadingState$ = this.store.loadingState$;
    this.loadingState$.pipe(takeUntilDestroyed()).subscribe(state => {
      this.color = state.screenColor;
      this.blockOverflow(state.isLoading);
      this.messageKey = state.messageKey;
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.store.setIsLoading(false);
      }
    });
  }

  blockOverflow(isLoading: boolean) {
    isLoading ? document.body.classList.add('loading') : document.body.classList.remove('loading');
  }
}
