import { EnvironmentProviders } from '@angular/core';
import { userInitializerProvider } from './user.provider';
import { historyInitializerProvider } from './history.provider';
import { talkJsInitializerProvider } from './talkjs.provider';

export const appInitializer: EnvironmentProviders[] = [
  userInitializerProvider,
  historyInitializerProvider,
  talkJsInitializerProvider,
];
